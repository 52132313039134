<template>
  <div>
    <div class="col-md-12">
      <div class="col-md-12" style="padding-left:0px; padding-right: 0px;">
        <div
          class="col-lg-3 col-md-3 col-sm-12"
          style="padding-left:0px; padding-right: 0px;"
        >
          <input
            style="margin-top: 10px;"
            type="text"
            v-model="searchKey"
            class="form-control"
            id="input-store"
            placeholder="Search Product"
            value=""
            name="voucher"
          />
        </div>
        <div
          class="col-lg-9 col-md-9 col-sm-12 text-right"
          style="padding-left:0px ;padding-right: 10px; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-end; align-items: center; align-content: stretch;"
        >
          <div style="margin-top: 10px;">
            From
            <datepicker
              style="display:inline-block; margin-right: 10px;"
              v-model="filterDatefrom"
              placeholder="Select Date"
            ></datepicker>
          </div>
          <div style="margin-top: 10px;">
            To
            <datepicker
              style="display:inline-block; margin-right: 10px;"
              calendar-class="my-datepicker_calendar"
              v-model="filterDateto"
              placeholder="Select Date"
            ></datepicker>
          </div>
          <div style="margin-top: 10px;">
            Status
            <select
              v-model="selectedFilterStatus"
              style="display:inline-block; width: 100px; margin-right: 10px;"
              class="form-control"
            >
              <option
                v-for="(item, index) in filterStatusArray"
                :key="index"
                :value="item.order_line_status"
                >{{ item.text }}</option
              >
            </select>
          </div>
          <div style="margin-top: 10px;">
            <button
              @click="setReturnOrder()"
              style="vertical-align: inherit; margin-right: 20px;"
              class="btn merchprof-sbtn"
            >
              Filter
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-12" style="padding-left: 0px;">
        <h2 class="title">Return Orders</h2>
      </div>
    </div>
    <div id="table-content" class="col-md-12">
      <table class="table table-striped table-border-out">
        <thead>
          <tr>
            <th scope="col" class="head-table"></th>
            <th scope="col" class="head-table">Order</th>
            <th scope="col" class="head-table">Status</th>
            <th scope="col" class="head-table">Quantity</th>
            <th scope="col" class="head-table">Amount</th>
            <th scope="col" class="head-table">Payment Method</th>
            <th scope="col" class="head-table">Action</th>
          </tr>
        </thead>

        <tbody>
          <div class="col-md-12" id="preview-area" v-if="blockLoading">
            <div class="row text-center">
              <div class="sk-chase">
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
              </div>
            </div>
          </div>

          <tr v-for="(itemp, index) in cart_products" :key="index">
            <td style="vertical-align: middle;" class="text-center col-md-1">
              <a
                ><img
                  width="70px"
                  :src="itemp.simple_product_id.images[0].image"
                  alt="Aspire Ultrabook Laptop"
                  title="Aspire Ultrabook Laptop"
                  class="img-thumbnail"
              /></a>
            </td>

            <td class="text-left col-md-4">
              <div class="row">
                <div
                  class="col-md-12"
                  style="font-size:14px; margin-bottom:5px;"
                >
                  <a
                    ><b>{{
                      itemp.simple_product_id.product_id.product_name
                    }}</b></a
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  Order ID : {{ itemp.order_env_number }}
                </div>
                <div class="col-md-6 col-sm-6">Date : {{ itemp.date }}</div>
              </div>
              <div class="row">
                <div
                  class="col-md-6 col-sm-6"
                  v-for="(itemp1, index1) in itemp.simple_product_id.attributes"
                  :key="index1"
                >
                  {{ itemp1.attribute_id.attribute_name }} :
                  {{ itemp1.attribute_value_id.lable }}
                </div>
              </div>
            </td>

            <td
              class="text-center col-md-2"
              style="vertical-align: middle; text-align: left;"
            >
              <span
                class="badge"
                style="background-color: #ffc107; border-radius: 4px;font-size: 10px; padding: 4px 6px;"
                v-if="itemp.dispute_status == 'Pending'"
                ><B>{{ itemp.dispute_status }}</B></span
              >
              <span
                class="badge"
                style="background-color: rgb(225, 83, 55); border-radius: 4px;font-size: 10px; padding: 4px 6px;"
                v-else-if="itemp.dispute_status == 'Cancelled'"
                ><B>{{ itemp.dispute_status }}</B></span
              >
              <span
                class="badge"
                style="background-color: #28a745; border-radius: 4px;font-size: 10px; padding: 4px 6px;"
                v-else-if="itemp.dispute_status == 'Merchant Approve'"
                ><B>{{ itemp.dispute_status }}</B></span
              >
              <span
                class="badge"
                style="background-color: rgb(225, 83, 55); border-radius: 4px;font-size: 10px; padding: 4px 6px;"
                v-else-if="itemp.dispute_status == 'Merchant Reject'"
                ><B>{{ itemp.dispute_status }}</B></span
              >
              <span
                class="badge"
                style="background-color: #dc3545; border-radius: 4px;font-size: 10px; padding: 4px 6px;"
                v-else-if="itemp.dispute_status == 'Send to Admin'"
                ><B>{{ itemp.dispute_status }}</B></span
              >
              <span
                class="badge"
                style="background-color: #dc3545; border-radius: 4px;font-size: 10px; padding: 4px 6px;"
                v-else-if="itemp.dispute_status == 'Admin Approve'"
                ><B>{{ itemp.dispute_status }}</B></span
              >
              <span
                class="badge"
                style="background-color: #dc3545; border-radius: 4px;font-size: 10px; padding: 4px 6px;"
                v-else-if="itemp.dispute_status == 'Pickup'"
                ><B>{{ itemp.dispute_status }}</B></span
              >
              <span
                class="badge"
                style="background-color: #dc3545; border-radius: 4px;font-size: 10px; padding: 4px 6px;"
                v-else-if="itemp.dispute_status == 'Return Approve'"
                ><B>{{ itemp.dispute_status }}</B></span
              >
              <span
                class="badge"
                style="background-color: #dc3545; border-radius: 4px;font-size: 10px; padding: 4px 6px;"
                v-else-if="itemp.dispute_status == 'Money Pass'"
                ><B>{{ itemp.dispute_status }}</B></span
              >
              <!-- <div class="left-content-product">
                <div class="content-product-right">	
                  
                    <div class="form-group box-info-product">
                      <div class="option quantity">
                          <span class="badge badge-secondary"><B>{{ itemp.dispute_status }}</B></span>
                        <div class="input-group quantity-control" unselectable="on" style="-webkit-user-select: none; margin-top: 16px;">
                          <label><B>{{ itemp.dispute_status }}</B></label>
                        </div>
                      </div>			 
                    </div>
                              
                </div>
              </div> -->
            </td>

            <td
              class="text-right"
              style="vertical-align: middle; text-align: left; font-weight: 700;"
            >
              <B>{{ itemp.order_line_id.qty }}</B> Items
            </td>
            <td
              class="text-right"
              style="vertical-align: middle; text-align: left; font-weight: 700;"
            >
              <B>Rs:</B> {{ itemp.order_line_id.total_charge }}
            </td>
            <td
              class="text-right"
              style="vertical-align: middle; text-align: left; font-weight: 700;"
              v-if="itemp.order_line_id.delivery_type === 1"
            >
              Cash on Delivery
            </td>
            <td
              class="text-right"
              style="vertical-align: middle; text-align: left; font-weight: 700;"
              v-if="itemp.order_line_id.delivery_type === 2"
            >
              Card Payment
            </td>

            <td
              class="text-right"
              style="vertical-align: middle; text-align: left;"
            >
              <button
                type="button"
                data-toggle="tooltip"
                title=""
                class="btn btn-primary"
                style=" background-color:rgb(0, 91, 170);"
                onclick=""
                data-original-title="View"
                @click="handdleChange(itemp)"
              >
                View
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row text-right">
      <div class="col-sm-12">
        <div class="mt-3">
          <b-pagination
            v-model="currentPage"
            :per-page="pageSize"
            :total-rows="rowcount"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import mixinOrder from "@/mixins/merchant/APIOrder";
export default {
  mixins: [mixinOrder],
  components: {
    Datepicker,
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "Return Order List",
          href: "/merchant-return-orders",
          active: false,
        },
      ],
      subTotal: 0,
      total: 0,

      inputNumValue: 1,

      no_of_cart_items: 0,
      cart_products: [],
      cartSavedProductIds: [],

      idObj: {
        ids: [],
      },

      selectedArray: [],
      pageSize: 5,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      blockLoading: true,
      filterDatefrom: new Date(),
      filterDateto: new Date(),
      filterStatusArray: [
        { order_line_status: "", text: "All" },
        { order_line_status: "0", text: "Cancelled" },
        { order_line_status: "1", text: "Pending" },
        { order_line_status: "10", text: "Merchant Approve" },
        { order_line_status: "20", text: "Merchant Reject" },
        { order_line_status: "30", text: "Send to Admin" },
        { order_line_status: "40", text: "Admin Approve" },
        { order_line_status: "50", text: "Pickup" },
        { order_line_status: "60", text: "Return Approve" },
        { order_line_status: "70", text: "Money Pass" },
      ],
      selectedFilterStatus: "",
    };
  },
  watch: {
    "$store.state.merchant_selectedstore": function() {
      this.getOrders();
    },
    searchKey: function(val) {
      //this.searchOrders(val);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {
      //this.searchOrders(this.searchKey);
    },
  },
  created() {
    this.handleBreadcrumb();
    this.filterDatefrom.setDate(this.filterDatefrom.getDate() - 14);
    this.setReturnOrder();
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    setReturnOrderCount: async function() {
      try {
        let params = "";
        if (this.selectedFilterStatus != "") {
          params += "&dispute_status=" + this.selectedFilterStatus;
        }
        if (this.searchKey != "") {
          params += "&search=" + this.searchKey;
        }

        params +=
          "&from_date=" + moment(this.filterDatefrom).format("YYYY-MM-DD");
        params += "&to_date=" + moment(this.filterDateto).format("YYYY-MM-DD");

        let responce = await this.getReturnOrderCount(params);

        this.rowcount = responce.result;
      } catch (error) {
        console.log(error);
      }
    },
    setReturnOrder: async function() {
      try {
        this.blockLoading = true;

        let params = "&page=" + this.currentPage;
        params += "&perpage=" + 5;

        if (this.selectedFilterStatus != "") {
          params += "&dispute_status=" + this.selectedFilterStatus;
        }
        if (this.searchKey != "") {
          params += "&search=" + this.searchKey;
        }

        params +=
          "&from_date=" + moment(this.filterDatefrom).format("YYYY-MM-DD");
        params += "&to_date=" + moment(this.filterDateto).format("YYYY-MM-DD");

        let responce = await this.getReturnOrder(params);
        this.cart_products = responce.map((obj) => {
          let dats = obj.order_id.date.split("GMT");
          obj.date = dats[0];

          if (obj.dispute_status === 1) {
            obj.dispute_status = "Pending";
          } else if (obj.dispute_status === 10) {
            obj.dispute_status = "Merchant Approve";
          } else if (obj.dispute_status === 20) {
            obj.dispute_status = "Merchant Reject";
          } else if (obj.dispute_status === 30) {
            obj.dispute_status = "Send to Admin";
          } else if (obj.dispute_status === 40) {
            obj.dispute_status = "Admin Approve";
          } else if (obj.dispute_status === 50) {
            obj.dispute_status = "Pickup";
          } else if (obj.dispute_status === 60) {
            obj.dispute_status = "Return Approve";
          } else if (obj.dispute_status === 70) {
            obj.dispute_status = "Money Pass";
          }
          return obj;
        });

        this.setReturnOrderCount();

        this.blockLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);

      this.currentPage = currentPage;

      this.setReturnOrder();
    },
    handdleChange: function(item) {
      this.$router.push({
        name: "Merchant Return Orders Configure",
        params: { id: item.order_env_number },
      });
    },
  },
};
</script>

<style scoped>
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  z-index: 5;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #5779ae;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

.merchprof-sbtn {
  background: #ffd338;
  color: #000000;
  border: none;
}
.merchprof-sbtn:hover {
  color: #ffffff;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}

.vdp-datepicker input {
  width: 120px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 34px;
  padding: 6px 12px;
}
.table-border-out {
  border: 1px solid #ddd;
}

@media screen and (max-width: 1280px) {
  #table-content {
    overflow-x: scroll;
  }

  table {
    width: 960px !important;
  }

  .img-thumbnail {
    max-width: 80px;
  }
}
</style>
